<template>
	<div id="webSite" class="el-content">
		<a-tabs v-model:activeKey="active" @change="changeTab">
			<a-tab-pane key="site" tab="网站设置">
				<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
					<a-form-item label="网站名称"><a-input v-model:value="form.web_title" /></a-form-item>
					<a-form-item label="网站标题"><a-input v-model:value="form.web_top_title" /></a-form-item>
					<a-form-item label="网站LOGO">
						<kd-img-select @success="e => {form.logo = e;}" :src="form.logo"></kd-img-select>
					</a-form-item>
					<a-form-item label="网站图标ICO">
						<kd-img-select @success="e => {form.ico = e;}" :src="form.ico"></kd-img-select>
					</a-form-item>
					<a-form-item label="网站底部信息">
						<tinymce-editor v-model="form.footer"></tinymce-editor>
					</a-form-item>
					<a-form-item label="登录背景图片">
						<kd-img-select @success="e=>form.login_back_url = e" :src="form.login_back_url"></kd-img-select>
					</a-form-item>
					<a-form-item label="登录前景图片">
						<kd-img-select @success="e => {form.login_front_back_url = e;}" :src="form.login_front_back_url"></kd-img-select>
					</a-form-item>
					
					<a-form-item label="是否开始注册">
						<a-radio-group v-model:value="form.is_register">
							<a-radio value="1">是</a-radio>
							<a-radio value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="是否允许从云端登录">
						<a-radio-group v-model:value="form.allow_cloud_entry">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 3 }"><a-button type="primary" @click="submitSave">保存</a-button></a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="system" tab="验证设置" force-render>
				<a-alert type="info">
					<template #description>
						<p>1.本系统验证使用的是极验<a href="www.geetest.com" target="_blank">geetest.com</a></p>
						<p>2.<a :href="$util.getStaticSrc('admin/jiyan.png')" target="_blank">点击查看ID和KEY获取方法</a></p>
					</template>
				</a-alert>
				<a-form  :label-col="{ span:3 }" :wrapper-col="{ span: 12 }">
					<a-form-item label="开启极验">
						<a-radio-group v-model:value="jiyanForm.jy_open_switch">
							<a-radio value="1">开启</a-radio>
							<a-radio value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
					<div v-if="jiyanForm.jy_open_switch == 1">
						<a-form-item label="极验ID"><a-input v-model:value="jiyanForm.jy_captcha_id" /></a-form-item>
						<a-form-item label="极验KEY"><a-input v-model:value="jiyanForm.jy_private_key" /></a-form-item>
					</div>
					<a-form-item :wrapper-col="{offset:3}">
						<a-button type="primary" @click="saveJiyanData">保存</a-button>
					</a-form-item>
				</a-form>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			active: 'site',
			form: {
				id:0,
				web_title: '',
				footer: '',
				logo: '',
				is_register: 1,
				login_back_url: '',
				web_top_title: '',
				allow_cloud_entry: 0,
				login_front_back_url:"",
				ico:''
			},
			jiyanForm: {
				jy_open_switch: '0',
				jy_captcha_id: '',
				jy_private_key: ''
			}
		})

		saasSetModel.getWebSite(res=>{
			state.id = res.id
			state.form = tool.filterForm(Object.keys(state.form),res)
		})

		function changeTab() {
			if (state.active == 'system') {
				saasSetModel.getSiteSet(['jiyan'],res=>{
					if( JSON.stringify(res.jiyan) != '[]'){
						state.jiyanForm = JSON.parse(res.jiyan)
					}
				})
			}
		}
		const saveJiyanData = ()=>saasSetModel.saveSiteSet({keys: { jiyan: JSON.stringify(state.jiyanForm) } })
		const submitSave = ()=>saasSetModel.saveWebSite(state.form)
		function getUrl(e){
			console.log('ewe',e);
			
		}
		return{
			...toRefs(state),
			submitSave,
			changeTab,
			saveJiyanData,
			getUrl
		}
	}
}
</script>

<style></style>
